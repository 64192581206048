.video-wrapper {
  width: auto;
  margin: 0 auto;
  box-shadow: 0px 10px 20px #00000029;
  border-radius: 30px;
}

.page {
  padding: 20px 15px;
}

.content {
  background: #F9F9F9;
  height: auto;
  /* overflow: auto; */
}

.content-no-video {
  background: #F9F9F9;
  height: 60vh;
  /* overflow: auto; */
}

.box-video {
  padding: 2px;
  background-color: #242426;
}