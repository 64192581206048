
  .container-input {
    text-align: center;
    background: #F5F5F6;
    border-top: 5px solid #1A73E8;
    padding: 40px 0;
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    max-width: 80%;
    font-size: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}

.iborrainputfile {
	font-size:20px; 
	font-weight:normal;
}

.inputfile-1 + label {
    color: #fff;
    background-color: #1A73E8;
    border-radius: 8px;
}

.inputfile-1:focus + label,
.inputfile-1.has-focus + label,
.inputfile-1 + label:hover {
    background-color: #5a9bf1;
}

.center-div {
    display: grid;
    place-items: center;
}